import React from "react";
import { Step } from "./NovaDivergenciaStepHandler";
import CorteIncorreto from "./tipos-components/corte/corte-incorreto/CorteIncorreto";
import PendentesPartesDoCorte from "./tipos-components/corte/pendentes-partes-cortes/PendentesPartesDoCorte";
import ProblemaComFichaTecnica from "./tipos-components/ficha-tecnica/ProblemaComFichaTecnica";
import AjusteDeGastoTecido from "./tipos-components/materia-prima/ajuste-de-gasto-tecido/AjusteDeGastoTecido";
import ComplementoDeGradeAviamento from "./tipos-components/materia-prima/complemento-de-grade-aviamento/ComplementoDeGradeAviamento";
import FaltaDeAviamento from "./tipos-components/materia-prima/falta-de-aviamento/FaltaDeAviamento";
import FaltaDeTecido from "./tipos-components/materia-prima/falta-de-tecido/FaltaDeTecido";
import NenhumMaterialChegou from "./tipos-components/materia-prima/NenhumMaterialChegou";
// import ProblemaComAviamento from './tipos-components/materia-prima/problema-com-aviamento/ProblemaComAviamento';
// import ProblemaComTecido from './tipos-components/materia-prima/problema-com-tecido/ProblemaComTecido';
import QualidadeDoAviamento from "./tipos-components/materia-prima/qualidade-do-aviamento/QualidadeDoAviamento";
import QualidadeDoTecido from "./tipos-components/materia-prima/qualidade-do-tecido/QualidadeDoTecido";
import SobraMateriaPrima from "./tipos-components/materia-prima/sobra-materia-prima/SobraMateriaPrima";
import PilotoEnviadoComProblema from "./tipos-components/piloto/PilotoEnviadoComProblema";
import PilotoNaoFoiEnviado from "./tipos-components/piloto/PilotoNaoFoiEnviado";
import RiscoErrado from "./tipos-components/riscos/risco-errado/RiscoErrado";
import RiscoNaoEnviado from "./tipos-components/riscos/RiscoNaoEnviado";
import DivergenciaNFxFisico from "./tipos-components/logistica/DivergenciaNFxFisico";
import FaltaDeMolde from "./tipos-components/modelagem/FaltaDeMolde";
import QuantidadeDiferenteNotaFiscal from "./tipos-components/logistica/QuantidadeDiferenteNotaFiscal";
import ReferenciaDiferenteNotaFiscal from "./tipos-components/logistica/ReferenciaDiferenteNotaFiscal";
import DuplicaReferencia from "./tipos-components/logistica/DuplicaReferencia";
import ItemBordado from "./tipos-components/logistica/ItemBordado";
import FaltouCorte from "./tipos-components/logistica/FaltouCorte";
import FaltouAviamento from "./tipos-components/logistica/FaltouAviamento";
import QuebraDeGradeMP from "./tipos-components/quebra-grade/QuebraDeGradeMP";
import DivergenciaEtiquetaxFisico from "./tipos-components/materia-prima/divergencia-etiqueta-x-fisico/DivergenciaEtiquetaxFisico";
import FaltaGabarito from "./tipos-components/corte/FaltaGabarito";
import ProblemaComRoteiro from "./tipos-components/ficha-tecnica/ProblemaComRoteiro";
import ProblemaComExplosaoInsumo from "./tipos-components/ficha-tecnica/ProblemaComExplosaoInsumo";
import EstampariaSemProcesso from "./tipos-components/estamparia/EstampariaSemProcesso";
import BordadoSemProcesso from "./tipos-components/bordado/BordadoSemProcesso";
import BolsoSemProcesso from "./tipos-components/pre-costura/BolsoSemProcesso";
import FaltaDeTecidoInterno from "./tipos-components/planejamento-demanda-interna/falta-de-tecido/FaltaDeTecidoInterno";
import MetragemEtiquetaDivergenteFisico from "./tipos-components/planejamento-demanda-interna/metragem-etiqueta-divergente-fisico/FaltaDeTecidoInterno";
import TecidoNaoReservado from "./tipos-components/planejamento-demanda-interna/tecido-nao-reservado/FaltaDeTecidoInterno";
import CorteIncorretoPlanejamento from "./tipos-components/planejamento-demanda-interna/corte-incorreto/FaltaDeTecidoInterno";
import EncaixeIncorreto from "./tipos-components/planejamento-demanda-interna/encaixe-incorreto/FaltaDeTecidoInterno";
import TecidoDivergente from "./tipos-components/planejamento-demanda-interna/tecido-divergente/FaltaDeTecidoInterno";
import { DuvidaMontagem, ErroModelagem } from "./tipos-components/modelagem";
import {
  AjusteComposicao,
  ProblemaFtProducao,
  ProblemaGraduacao,
  ProblemaRisco,
} from "./tipos-components/cad-atelier";

import MisturaLotesEtiquetaIncorreta from "./tipos-components/corte/mistura-lotes-etiqueta-incorreta/MisturaLotesEtiquetaIncorreta";
import FaltaVies from "./tipos-components/corte/falta-vies/FaltaVies";
import TonalidadeMesmoLote from "./tipos-components/corte/tonalidade-mesmo-lote/TonalidadeMesmoLote";
import FaltaPecas from "./tipos-components/corte/falta-pecas/FaltaPecas";

import { EnvioSemFullKit } from "./tipos-components/materia-prima/envio-sem-full-kit";
import AtualizacaoGrade from "./tipos-components/planejamento-demanda-interna/atualizacao-grade/AtualizacaoGrade";
import InclusaoCodBarras from "./tipos-components/logistica/InclusaoCodBarras";
import ArquivoRisco from "./tipos-components/riscos/ArquivoRisco"
import {
  AjuesteNecessidadeMP,
  AumentoGradeOPStep,
  ReducaoGradeOPStep,
  CancelamentoOPFaltaMP,
  SubstituicaoMP,
} from "./tipos-components/ajuste-mp";
import {
  MaterialNaoLocalizadoTecido,
  MaterialNaoLocalizadoAviamento,
  SaldoEmDevolucaoAviamento,
  SaldoEmDevolucaoTecido,
  SaldoFiscalAviamento,
  SaldoFiscalTecido,
  ProblemaDeQualidadeTecido,
  ProblemaDeQualidadeAviamento,
  OutrosAviamento,
  OutrosTecido,
} from "./tipos-components/anomalia";
import ReacaoTecidoPosCorte from "./tipos-components/corte/reacao-tecnica-pos-corte/ReacaoTecidoPosCorte";

export interface GrupoTipoDivergencia {
  tipos: TipoDivergencia[];
  title: string;
  hideCategory: number[];
}

export interface TipoDivergenciaComponentProps {
  onBack: (step?: Step) => void;
  onClose: Function;
  onSetStep: (step: Step) => void;
  payload?: Object;
}

export type TipoDivergenciaComponent<T = TipoDivergenciaComponentProps> =
  React.ComponentType<T>;

export interface TipoDivergencia {
  component: TipoDivergenciaComponent;
  title: string;
  description?: string;
  hideSubCategory: number[];
}

// Obs: O objeto hideSubCategory tem os ids de marca para qual deve ser exibido
// Obs: O objeto hideCategory tem os ids de marca pra qual deveser exibida
// Ao adicionar novas divergencias, confere a marca que esta vai atender.

const allBrands = [
  3, 1, 4, 8, 2, 5, 7, 29, 0, 11, 12, 13, 21, 22, 23, 24, 31, 32, 33, 41, 42, 43,
  51, 52, 53, 6, 63, 71, 72, 9, 98, 99, 10, 10000, 10001, 14, 97, 15, 16, 17,
  18, 19, 20, 96,
];

export const gruposTipoDivergencia: GrupoTipoDivergencia[] = [
  {
    title: "Corte",
    hideCategory: [
      1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
    ],
    tipos: [
      {
        title: "Corte incorreto", //ok
        description:
          "Produto foi cortado com a localização da estampa na área incorreta, 'não está casando'; Corte irregular.",
        component: CorteIncorreto,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Pendentes partes do corte", //ok
        description:
          "Falta alguma parte do corte que foi enviado; Quando há falta do viés.",
        component: PendentesPartesDoCorte,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Falta Gabarito", //ok
        component: FaltaGabarito,
        hideSubCategory: [17, 18, 19, 20],
      },
      {
        title: "Mistura de lotes / Etiquetagem incorreta", //ok
        component: MisturaLotesEtiquetaIncorreta,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Falta viés", //ok
        component: FaltaVies,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Tonalidade no mesmo lote", //ok
        component: TonalidadeMesmoLote,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Falta de peças", //ok
        component: FaltaPecas,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Reação de Tecido Pós Corte", //ok
        description: "Reação de Tecido Pós Corte",
        component: ReacaoTecidoPosCorte,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
    ],
  },
  {
    title: "Ficha técnica",
    hideCategory: [
      1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
    ],
    tipos: [
      {
        title: "Problema com a ficha técnica", //ok
        description:
          "Quando as medidas da piloto não batem com a tabela de medidas ou faltam informações de acabamento; Graduação na tabela de medidas com a numeração errada; Risco com tamanho divergente da FT (ficha técnica com tamanho P,M e G x modelagem com grade numerada); Divergência de aviamento entre FT e piloto.",
        component: ProblemaComFichaTecnica,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Problema com o roteiro", //ok
        component: ProblemaComRoteiro,
        hideSubCategory: [17, 18, 19, 20],
      },
      {
        title: "Problema com explosão de insumo", //ok
        component: ProblemaComExplosaoInsumo,
        hideSubCategory: [17, 18, 19, 20],
      },
    ],
  },
  {
    title: "Matéria-prima",
    hideCategory: [
      1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
    ],
    tipos: [
      {
        title: "Ajuste de gasto do tecido", //ok
        component: AjusteDeGastoTecido,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Falta de tecido", //ok
        component: FaltaDeTecido,
        description:
          "Quando a metragem de tecido recebida não é suficiente para produzir todo pedido. Importante! Sempre informar o motivo da falta, como por exemplo: recebeu a menos, tecido com defeito, alteração de consumo, perda do tecido, etc.",
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Qualidade do tecido", //ok
        description:
          "Quando o gasto do tecido for diferente do que o informado para o estilo.",
        component: QualidadeDoTecido,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Sobra de matéria-prima", //ok
        description:
          "Quando conseguir cortar a grade inteira (ou até 10% a mais) e sobra tecido. Importante! Entrar em contato com o PCP antes de efetuar o corte do tecido.",
        component: SobraMateriaPrima,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Complemento de grade - Aviamento", //ok
        description:
          "Quando peças a mais que o requisitado pelo pedido podem ser produzidas e consequentemente, serão necessários mais aviamentos.",
        component: ComplementoDeGradeAviamento,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Falta de aviamento", //ok
        description:
          "Quando a quantidade de aviamentos recebida não é suficiente para produzir todo pedido. Importante! Sempre informar o motivo da falta, como por exemplo: recebeu a menos, aviamento com defeito, alteração de consumo, perda do aviamento, etc.",
        component: FaltaDeAviamento,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Qualidade do aviamento", //ok
        description:
          "Quando um aviamento possui algum defeito ou partes faltando. Casos de etiqueta de composição com a escrita incorreta, borrada ou rasgada também entram nesta categoria. Importante! Sempre anexar imagens e, em casos de etiqueta de composição, informar o defeito.",
        component: QualidadeDoAviamento,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Nenhum material chegou", //ok
        description:
          "Quando todos os volumes não são recebidos, após X dias da emissão do pedido, de acordo com a região. Entende-se por volume os pacotes contendo aviamentos ou o tecido.",
        component: NenhumMaterialChegou,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Divergência Etiqueta x Físico", //ok
        description:
          "Deve ser usado quando o rolo do tecido está etiquetado errado. Por exemplo: diferença de cor descrita na etiqueta e cor do rolo físico.",
        component: DivergenciaEtiquetaxFisico,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Envio sem Full Kit",
        component: EnvioSemFullKit,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Piloto",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title: "Piloto enviado com problema", //ok
        description:
          "Piloto enviada incorretamente. Por exemplo: referência errada, lacre errado.",
        component: PilotoEnviadoComProblema,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Piloto não foi enviado", //ok
        description:
          "Recebeu os materiais para produção, mas não recebeu a piloto junto.",
        component: PilotoNaoFoiEnviado,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Risco",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title: "Risco errado", //ok
        description:
          "Quando risco está divergente da piloto lacrada; Quando há divergência de grade do risco, ajuste de largura, localização errada, sentido do risco (com pé, casando xadrez/listra, etc.).",
        component: RiscoErrado,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Risco não enviado", //ok
        description: "Quando o risco não foi enviado junto com os materiais.",
        component: RiscoNaoEnviado,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Estamparia",
    hideCategory: [17, 18, 19, 20],
    tipos: [
      {
        title: "Estamparia sem processo", //ok
        component: EstampariaSemProcesso,
        hideSubCategory: [17, 18, 19, 20],
      },
    ],
  },
  {
    title: "Bordado",
    hideCategory: [17, 18, 19, 20],
    tipos: [
      {
        title: "Bordado sem processo", //ok
        component: BordadoSemProcesso,
        hideSubCategory: [17, 18, 19, 20],
      },
    ],
  },
  {
    title: "Pré costura",
    hideCategory: [17, 18, 19, 20],
    tipos: [
      {
        title: "Bolso sem processo", //ok
        component: BolsoSemProcesso,
        hideSubCategory: [17, 18, 19, 20],
      },
    ],
  },
  {
    title: "Modelagem",
    hideCategory: allBrands,
    tipos: [
      {
        title: "Dúvida de montagem",
        description:
          "Dúvidas quanto a costura ou construção da peça; Dúvidas que não são tiradas visualmente pela modelagem, FT e/ou piloto e precisam de instrução detalhada. Por exemplo: nó diferenciado, drapeado, pregas, partes embutidas, lastex com bitola, etc.",
        component: DuvidaMontagem,
        hideSubCategory: allBrands,
      },
      {
        title: "Falta de Molde",
        description:
          "Falta parte do molde para conclusão da peça; Modelagem não está completa. Por exemplo: faltou uma parte da frente, falta o forro, etc.",
        component: FaltaDeMolde,
        hideSubCategory: allBrands,
      },
      {
        title: "Erro de modelagem",
        description:
          "Partes da modelagem não casam; Modelagem diferente da piloto; Posicionamento ou falta de pique, etc. Por exemplo: piloto de tamanho diferente da modelagem, partes não se encaixam, peça não espelhada, etc.",
        component: ErroModelagem,
        hideSubCategory: allBrands,
      },
    ],
  },
  {
    title: "Encaixe/Modelagem",
    hideCategory: allBrands,
    tipos: [
      {
        title: "Problema no risco",
        description:
          "Risco divergente da piloto lacrada; Divergência de grade do risco; Ajuste de largura; Localização errada, sentido do risco (com pé, casando xadrez/listra, etc.).",
        component: ProblemaRisco,
        hideSubCategory: allBrands,
      },
      {
        title: "Problema na graduação",
        description:
          "Quando o tamanho base está correto e os demais tamanhos divergentes; Quando um lado não casa com o outro nos demais tamanhos, etc.",
        component: ProblemaGraduacao,
        hideSubCategory: allBrands,
      },
      {
        title: "Problema de ficha técnica de produção",
        description:
          "Quando as medidas da piloto não batem com a tabela de medidas ou faltam informações de acabamento; Graduação na tabela de medidas com a numeração errada; Risco com tamanho divergente da FT (ficha técnica com tamanho P,M e G x modelagem com grade numerada); Divergência de aviamento entre FT e piloto.",
        component: ProblemaFtProducao,
        hideSubCategory: allBrands,
      },
      {
        title: "Ajuste de composição",
        description:
          "Quando há divergência ou dúvidas entre a composição do produto e a composição da etiqueta. Por exemplo: peça com forro e não contempla na composição.",
        component: AjusteComposicao,
        hideSubCategory: allBrands,
      },
    ],
  },
  {
    title: "Logística",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title: "Divergência NF x Físico", //ok
        description:
          "Quando algum dos volumes da NF é enviado errado. Por exemplo: recebeu item de outro fornecedor (verificar etiqueta).",
        component: DivergenciaNFxFisico,
        hideSubCategory: [
          1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 17, 18, 19, 20, 24,
        ],
      },
      {
        title: "Quantidade de volumes físicos diferentes da nota fiscal", //ok
        component: QuantidadeDiferenteNotaFiscal,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Referência diferente da nota fiscal", //ok
        component: ReferenciaDiferenteNotaFiscal,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Mais de uma referência recebida no mesmo lote", //ok
        component: DuplicaReferencia,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title:
          "Itens que necessitam bordado sem as partes bordadas ou sem as partes normais", //ok
        description:
          "Quando alguma das partes que deveriam ser bordadas não foram enviadas ou sem partes normais.",
        component: ItemBordado,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Recebeu aviamento, faltou corte", //ok
        description:
          "Quando recebeu os volumes referentes aos aviamentos, mas não recebeu os volumes referentes aos cortes.",
        component: FaltouCorte,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
      {
        title: "Recebeu corte, faltou aviamento", //ok
        description:
          "Quando recebeu os volumes referentes aos cortes, mas não recebeu os volumes referentes aos aviamentos.",
        component: FaltouAviamento,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Arquivo Cód.Barras (demanda interna)",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title: "Inclusão de Arquivo Cód.Barras (demanda interna)", //ok
        component: InclusaoCodBarras,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Inclusão de Arquivo de Risco (demanda interna)",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title: "Inclusão de Arquivo de Risco (demanda interna)", //ok
        component: ArquivoRisco,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Informar quebra de grade",
    hideCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
    tipos: [
      {
        title:
          "Quebra de grade por falta de matéria prima comprada pelo fornecedor", //ok
        description:
          "Quando não conseguir produzir todo o pedido e a quebra for maior que 5% do total da produção.",
        component: QuebraDeGradeMP,
        hideSubCategory: [1, 2, 3, 4, 5, 7, 8, 9, 10, 14, 15, 16, 97, 24],
      },
    ],
  },
  {
    title: "Planejamento de Demanda",
    hideCategory: allBrands,
    tipos: [
      {
        title: "Falta de tecido - Demanda Interna", //ok
        description:
          "Quando a metragem de tecido recebida não é suficiente para produzir todo pedido. Importante! Sempre informar o motivo da falta, como por exemplo: recebeu a menos, tecido com defeito, alteração de consumo, perda do tecido, etc.",
        component: FaltaDeTecidoInterno,
        hideSubCategory: allBrands,
      },
      {
        title: "Metragem da etiqueta divergente do físico", //ok
        component: MetragemEtiquetaDivergenteFisico,
        hideSubCategory: allBrands,
      },
      {
        title: "Tecido não reservado", //ok
        component: TecidoNaoReservado,
        hideSubCategory: allBrands,
      },
      {
        title: "Corte realizado de forma incorreta", //ok
        component: CorteIncorretoPlanejamento,
        hideSubCategory: allBrands,
      },
      {
        title: "Encaixe incorreto", //ok
        component: EncaixeIncorreto,
        hideSubCategory: allBrands,
      },
      {
        title: "Tecido divergente", //ok
        description:
          "Quando o gasto do tecido for diferente do que o informado para o estilo.",
        component: TecidoDivergente,
        hideSubCategory: allBrands,
      },
      {
        title: "Atualização de grade", //ok
        component: AtualizacaoGrade,
        hideSubCategory: allBrands,
      },
    ],
  },
  {
    title: "Ajuste de matéria prima",
    hideCategory: allBrands,
    tipos: [
      {
        title: "Redução de grade em ordem de produção",
        component: ReducaoGradeOPStep,
        hideSubCategory: allBrands,
      },
      {
        title: "Aumento de grade em ordem de produção",
        component: AumentoGradeOPStep,
        hideSubCategory: allBrands,
      },
      {
        title: "Substituição de mp",
        component: SubstituicaoMP,
        hideSubCategory: allBrands,
      },
      {
        title: "Cancelamento de ordem de produção por falta de mp",
        component: CancelamentoOPFaltaMP,
        hideSubCategory: allBrands,
      },
      {
        title: "Ajuste de necessidade de MP",
        component: AjuesteNecessidadeMP,
        hideSubCategory: allBrands,
      },
    ],
  },
  {
    title: "Anomalia",
    hideCategory: allBrands,
    tipos: [
      {
        title: "Material não localizado Tecido",
        component: MaterialNaoLocalizadoTecido,
        hideSubCategory: allBrands,
      },
      {
        title: "Material não localizado Aviamento",
        component: MaterialNaoLocalizadoAviamento,
        hideSubCategory: allBrands,
      },
      {
        title: "Saldo em Devolução Aviamento",
        component: SaldoEmDevolucaoAviamento,
        hideSubCategory: allBrands,
      },
      {
        title: "Saldo em Devolução Tecido",
        component: SaldoEmDevolucaoTecido,
        hideSubCategory: allBrands,
      },
      {
        title: "Saldo Fiscal Aviamento",
        component: SaldoFiscalAviamento,
        hideSubCategory: allBrands,
      },
      {
        title: "Saldo Fiscal Tecido",
        component: SaldoFiscalTecido,
        hideSubCategory: allBrands,
      },
      {
        title: "Problema de Qualide Tecido",
        component: ProblemaDeQualidadeTecido,
        hideSubCategory: allBrands,
      },
      {
        title: "Problema de Qualidade Aviamento",
        component: ProblemaDeQualidadeAviamento,
        hideSubCategory: allBrands,
      },
      {
        title: "Outros Tecido",
        component: OutrosTecido,
        hideSubCategory: allBrands,
      },
      {
        title: "Outros Aviamentos",
        component: OutrosAviamento,
        hideSubCategory: allBrands,
      },
    ],
  },
];
