enum TipoDivergenciasKey {
  CORTE_INCORRETO = "CORTE_INCORRETO ",
  SOBRAS_MATERIAL = "SOBRAS_MATERIAL",
  CORTE_PENDENTES_PARTES_DO_CORTE = "CORTE_PENDENTES_PARTES_DO_CORTE ",
  FICHA_PROBLEMA_COM_FICHA_TECNICA = "FICHA_PROBLEMA_COM_FICHA_TECNICA ",
  ME_PROBLEMA_COM_PAGAMENTO = "ME_PROBLEMA_COM_PAGAMENTO ",
  ME_PROBLEMA_NA_VISTORIA_DE_QUALIDADE_DO_PRODUTO = "ME_PROBLEMA_NA_VISTORIA_DE_QUALIDADE_DO_PRODUTO ",
  MP_AJUSTE_DE_GASTO_DO_TECIDO = "MP_AJUSTE_DE_GASTO_DO_TECIDO ",
  MP_COMPLEMENTO_DE_GRADE_AVIAMENTO = "MP_COMPLEMENTO_DE_GRADE_AVIAMENTO ",
  MP_FALTA_DE_AVIAMENTO = "MP_FALTA_DE_AVIAMENTO ",
  MP_FALTA_DE_TECIDO = "MP_FALTA_DE_TECIDO ",
  MP_NENHUM_MATERIAL_CHEGOU = "MP_NENHUM_MATERIAL_CHEGOU ",
  MP_PROBLEMA_COM_AVIAMENTO = "MP_PROBLEMA_COM_AVIAMENTO ",
  MP_PROBLEMA_COM_TECIDO = "MP_PROBLEMA_COM_TECIDO ",
  MP_QUALIDADE_DO_AVIAMENTO = "MP_QUALIDADE_DO_AVIAMENTO ",
  MP_ENVIO_SEM_FULL_KIT = "MP_ENVIO_SEM_FULL_KIT",
  MP_QUALIDADE_DO_TECIDO = "MP_QUALIDADE_DO_TECIDO ",
  MP_SOBRA_DE_MATERIA_PRIMA = "MP_SOBRA_DE_MATERIA_PRIMA ",
  PILOTO_ENVIADO_COM_PROBLEMA = "PILOTO_ENVIADO_COM_PROBLEMA ",
  PILOTO_NAO_ENVIADO = "PILOTO_NAO_ENVIADO ",
  RISCO_ERRADO = "RISCO_ERRADO ",
  RISCO_NAO_ENVIADO = "RISCO_NAO_ENVIADO ",
  PROBLEMA_MODELAGEM = "PROBLEMA_MODELAGEM ",
  ERRO_MODELAGEM = "ERRO_MODELAGEM ",
  DIVERGENCIA_NF_X_FISICO = "DIVERGENCIA_NF_X_FISICO",
  MOLDE_INCORRETO = "MOLDE_INCORRETO",
  FALTA_DE_MOLDE = "FALTA_DE_MOLDE",
  DUVIDA_MOLDE = "DUVIDA_MOLDE",
  DUVIDA_MONTAGEM = "DUVIDA_MONTAGEM",
  ROUPAS_NAO_MONTANDO_DEVIDO_AO_TIPO_DE_MODELAGEM = "ROUPA_NAO_MONTANDO_DEVIDO_AO_TIPO_DE_MODELAGEM",
  QUANTIDADE_DIFERENTE_NOTA_FISCAL = "QUANTIDADE_DIFERENTE_NOTA_FISCAL",
  REFERENCIA_DIFERENTE_NOTA_FISCAL = "REFERENCIA_DIFERENTE_NOTA_FISCAL",
  DUPLICA_REFERENCIA = "DUPLICA_REFERENCIA",
  ITEM_BORDADO = "ITEM_BORDADO",
  FALTOU_CORTE = "FALTOU_CORTE",
  FALTOU_AVIAMENTO = "FALTOU_AVIAMENTO",
  AJUSTE_GRADE = "AJUSTE_GRADE",
  AJUSTE_RAPPORT = "AJUSTE_RAPPORT",
  FALTA_PRINT = "FALTA_PRINT",
  AJUSTE_MODELAGEM = "AJUSTE_MODELAGEM",
  AJUSTE_COMPOSICAO = "AJUSTE_COMPOSICAO",
  PROBLEMA_FICHA_TECNICA_PRODUCAO = "PROBLEMA_FICHA_TECNICA_PRODUCAO",
  PROBLEMA_GRADUACAO = "PROBLEMA_GRADUACAO",
  PROBLEMA_RISCO = "PROBLEMA_RISCO",
  ESTAMPA_X_PRINT = "ESTAMPA_X_PRINT",
  QUEBRA_DE_GRADE_FALTA_MP_FORN = "QUEBRA_DE_GRADE_FALTA_MP_FORN",
  DIVERGENCIA_ETIQUETA_X_FISICO = "DIVERGENCIA_ETIQUETA_X_FISICO",
  FALTA_GABARITO = "FALTA_GABARITO",
  PROBLEMA_COM_ROTEIRO = "PROBLEMA_COM_ROTEIRO",
  PROBLEMA_COM_EXPLOSAO_INSUMO = "PROBLEMA_COM_EXPLOSAO_INSUMO",
  ESTAMPARIA_SEM_PROCESSO = "ESTAMPARIA_SEM_PROCESSO",
  BORDADO_SEM_PROCESSO = "BORDADO_SEM_PROCESSO",
  BOLSO_SEM_PROCESSO = "BOLSO_SEM_PROCESSO",
  MP_FALTA_DE_TECIDO_INTERNO = "MP_FALTA_DE_TECIDO_INTERNO",
  METRAGEM_ETIQUETA_DIVERGENTE_FISICO = "METRAGEM_ETIQUETA_DIVERGENTE_FISICO",
  TECIDO_NAO_RESERVADO = "TECIDO_NAO_RESERVADO",
  CORTE_INCORRETO_PLANEJAMENTO = "CORTE_INCORRETO_PLANEJAMENTO",
  ENCAIXE_INCORRETO = "ENCAIXE_INCORRETO",
  TECIDO_DIVERGENTE = "TECIDO_DIVERGENTE",
  MISTURA_LOTES_ETIQUETAGEM_INCORRETA = "MISTURA_LOTES_ETIQUETAGEM_INCORRETA",
  FALTA_VIES = "FALTA_VIES",
  TONALIDADE_MESMO_LOTE = "TONALIDADE_MESMO_LOTE",
  FALTA_DE_PECAS = "FALTA_DE_PECAS",
  ATUALIZACAO_DE_GRADE = "ATUALIZACAO_DE_GRADE",
  INCLUSAO_ARQUIVO_COD_BARRAS = "INCLUSAO_ARQUIVO_COD_BARRAS",
  INCLUSAO_ARQUIVO_RISCO = "INCLUSAO_ARQUIVO_RISCO",
  REDUCAO_DE_GRADE_EM_OP = "REDUCAO_DE_GRADE_EM_OP",
  AUMENTO_DE_GRADE_EM_OP = "AUMENTO_DE_GRADE_EM_OP",
  SUBSTITUICAO_DE_MP = "SUBSTITUICAO_DE_MP",
  CANCELAMENTO_DE_OP_POR_FALTA_DE_MP = "CANCELAMENTO_DE_OP_POR_FALTA_DE_MP",
  AJUSTE_DE_NECESSIDADE_DE_MP = "AJUSTE_DE_NECESSIDADE_DE_MP",
  MATERIAL_NAO_LOCALIZADO_TECIDO = "MATERIAL_NAO_LOCALIZADO_TECIDO",
  MATERIAL_NAO_LOCALIZADO_AVIAMENTO = "MATERIAL_NAO_LOCALIZADO_AVIAMENTO",
  SALDO_EM_DEVOLUCAO_TECIDO = "SALDO_EM_DEVOLUCAO_TECIDO",
  SALDO_EM_DEVOLUCAO_ANDAMENTO = "SALDO_EM_DEVOLUCAO_ANDAMENTO",
  SALDO_FISCAL_TECIDO = "SALDO_FISCAL_TECIDO",
  SALDO_FISCAL_AVIAMENTO = "SALDO_FISCAL_AVIAMENTO",
  PROBLEMA_DE_QUALIDADE_TECIDO = "PROBLEMA_DE_QUALIDADE_TECIDO",
  PROBLEMA_DE_QUALIDADE_AVIAMENTO = "PROBLEMA_DE_QUALIDADE_AVIAMENTO",
  OUTROS_TECIDO = "OUTROS_TECIDO",
  OUTROS_AVIAMENTO = "OUTROS_AVIAMENTO",
  REACAO_TECIDO_POS_CORTE = "REACAO_TECIDO_POS_CORTE",
}

export default TipoDivergenciasKey;
