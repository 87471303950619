import React,  { createContext, ReactNode, useState } from 'react';


interface ProductContextProps {
  children: ReactNode;
}

type OrdemServico = {
  ordem_servico: string;
};

type OrdemProducao = {
  ordem_producao: string;
  op_cancelada: boolean;
  Ordem_Servico: OrdemServico[];
};

type ProducaoPPPOP = {
  FarolOneBeat: any | null; 
  Ordem_Producao: OrdemProducao;
};

type Produto = {
  desc_produto: string;
};

type ProdutoCor = {
  Produto: Produto;
};

type Colecao = {
  grupo_colecao: string;
};

type Product = {
  id_producao_prog_prod: number;
  ano: number;
  Colecao: Colecao;
  Produto_Cor: ProdutoCor;
  Producao_PPP_OP: ProducaoPPPOP[];
};


export type ProductContextType = {
  product: Product | null;
  setProduct: (product: Product | null) => void;
}

export const ProductContext = createContext<ProductContextType>({} as ProductContextType);

export const ProductContextProvider: React.FC<ProductContextProps> = ({children} : ProductContextProps) => {
  const [product, setProduct] = useState<Product | null>(null);

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {children}
    </ProductContext.Provider>
  );
};


