import { useQuery } from '@apollo/client';
import { Box, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { loader } from 'graphql.macro';
import _get from 'lodash.get';
import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductInfo from '../../../components/ProductInfo';
import { SearchComponent } from '../../../components/SearchComponent';
import { buildColecaoAno } from '../../Utils';
import BufferPenetrationColor from './BufferPenetrationColor';
import { ProductContext, ProductContextType } from '../../../context/Product/ProductContext';

const query = loader('../queries/ProductInfoQuery.gql');

const ProductInfoWrapper: FunctionComponent = () => {
   const { setProduct } = useContext<ProductContextType>(ProductContext);

  const { idProducaoProgProd, idProducaoPppOp } = useParams<{
    idProducaoProgProd: string;
    idProducaoPppOp: string;
  }>();

  const { data, loading } = useQuery(query, {
    variables: {
      idProducaoProgProd: parseInt(idProducaoProgProd, 10),
      idProducaoPppOp: parseInt(idProducaoPppOp, 10),
    },
  });
  const product = _get(data, 'findManyProducao_Prog_Prod[0]');
  const colecao = buildColecaoAno(
    _get(product, 'Colecao.grupo_colecao'),
    _get(product, 'ano')
  );

  const color = _get(
    product,
    'Producao_PPP_OP[0].FarolOneBeat.buffer_penetration_color',
    'Light Gray'
  );

  useEffect(() => {
    setProduct(product);
  }, [product, setProduct]);

  return (
    <Grid container item xs={12} alignItems='center'>
      <Grid container item xs={9} justify='flex-start' alignItems='center'>
        <Grid item xs={5}>
          <Box marginY={2} clone>
            <div>
              {loading ? (
                <Skeleton animation='wave' height={48} />
              ) : (
                <div className='d-flex'>
                  <Typography
                    variant='h4'
                    className='headline primary500 text-truncate'
                    style={{ width: 'min-content' }}
                  >
                    {_get(product, 'Produto_Cor.Produto.desc_produto')}
                  </Typography>
                  <BufferPenetrationColor
                    color={color}
                  ></BufferPenetrationColor>
                </div>
              )}
            </div>
          </Box>
        </Grid>
        <ProductInfo
          loading={loading}
          op={_get(product, 'Producao_PPP_OP[0].Ordem_Producao.ordem_producao')}
          os={_get(
            product,
            'Producao_PPP_OP[0].Ordem_Producao.Ordem_Servico[0].ordem_servico'
          )}
          colecao={colecao}
        ></ProductInfo>
      </Grid>
      <SearchComponent></SearchComponent>
    </Grid>
  );
};

export default ProductInfoWrapper;
