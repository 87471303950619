import { Avatar, Button, Grid } from '@material-ui/core';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import ColorHash from 'color-hash';
import moment from 'moment';
import React, { FunctionComponent, useContext } from 'react';
import { DivergenceContext, DivergenceContextType } from "../../../../../context";
import {
  buildUserName,
  momentFormatShortDayWithHour,
} from '../../../../../support/Utils';
import { useLoggedUser } from '../../../../../../app/support/UseLoggedUser';
import { Arquivo, Mensagem } from '../DivergenciaDetailTypes';
import MensagemValue from './MensagemValue';

const colorHash = new ColorHash();

const MensagemComponent: FunctionComponent<{ mensagem: Mensagem }> = ({
  mensagem,
}) => {
  const avatarColor = colorHash.hex(mensagem.EnviadoPor.login);

  const { setDeleteFileSelected, setShowDeleteFile } = useContext<DivergenceContextType>(DivergenceContext);
  const { data } = useLoggedUser();

  const superUser = data?.getLoggedUser.super_user;

  const handleShowDeleteFile = (file: Arquivo) => {
    setDeleteFileSelected(file);
    setShowDeleteFile(true);
  }

  return (
    <Grid item xs={12} key={`mensagem-${mensagem.id_mensagem}`}>
      <div className='d-flex'>
        <Avatar style={{ backgroundColor: avatarColor }}>
          {mensagem.EnviadoPor.login.substr(0, 1).toUpperCase()}
        </Avatar>
        <div className='ml16' style={{ width: '100%' }}>
          <div className='mt8'>
            <span className='body2 semi-bold neutral700 '>
              {buildUserName(mensagem.EnviadoPor.login)}
            </span>
            <span className='ml16 overline bold neutral400'>
              {moment(mensagem.created_at).format(momentFormatShortDayWithHour)}
            </span>
          </div>
          <div className='mt8 body2 medium neutral700'>
            <MensagemValue mensagem={mensagem.mensagem}></MensagemValue>
          </div>
          {mensagem.Arquivos.length > 0 && (
            <div style={{ width: '100%' }}>
              <div className='caption medium neutral500'>Arquivos anexados</div>
              {mensagem.Arquivos.map((arquivo, index) => {
                return (
                  <>
                    {arquivo.fl_ativo ?
                      <div
                        className='d-flex align-items-center'
                        style={{ justifyContent: 'space-between', width: '100%' }}
                        key={`arquivo${index}`}
                      >
                        <div>
                          {superUser ?
                            <DeleteRoundedIcon
                              color="error"
                              onClick={() => handleShowDeleteFile(arquivo)}
                              style={{ marginLeft: '-5px', marginBottom: '-5px', cursor: 'pointer' }}
                            /> : null
                          }
                          <span className='body2 medium neutral700'>
                            {arquivo.nome_original}
                          </span>
                        </div>
                        <Button
                          className='overline bold button primary500'
                          href={arquivo.url}
                          target='_blank'
                        >
                          Download
                        </Button>
                      </div> : null
                    }
                  </>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

export default MensagemComponent;
