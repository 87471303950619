import { useQuery } from '@apollo/client';
import {
  Box,
  createStyles,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { FunctionComponent, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/info.svg';
import UserAvatar from '../../../../../support/components/UserAvatar';
import ChatInput, { CustomFile } from '../../components/chat/ChatInput';
import ColaboradorResponsavelComponent from '../../components/ColaboradorResponsavelComponent';
import { Area } from '../AreaTipoDivergenciaEnum';
import AreaResponsavelComponent from '../components/AreaResponsavelComponent';
import LabelValueComponent from '../components/LabelValueComponent';
import NovaDivergenciaProgressBar from '../components/NovaDivergenciaProgressBar';
import { Step } from '../NovaDivergenciaStepHandler';
import FinalStepFooter from './FinalStepFooter';
import FinalStepHeader from './FinalStepHeader';
import TamanhoGrade from '../TamanhoGrade';
import { TagPreco } from '../TagPreco';
import { TamanhoGradeContext, TamanhoGradeContextType } from '../../../../../context/TamanhoGradeContext';
import TipoDivergenciasKey from '../TipoDivergenciasKey';

const responsavelQuery = loader('../../queries/FindResponsavelArea.gql');

export function useBuildDefaultInfoComponent(
  area: Area,
  tipoDivergencia: string,
  key?: TipoDivergenciasKey
) {
  moment.locale('pt-br');
  const { idProducaoPppOp } = useParams<{ idProducaoPppOp: string }>();

  const { loading: loadingResponsavel, data } = useQuery(responsavelQuery, {
      variables: {
        area: area.id,
        idPpoOp: Number(idProducaoPppOp),
      },
    });
   

  // Implementação temporária para a geração de chamados de inclusão de código de barras.
  let loginControlePCP 
  if(TipoDivergenciasKey.INCLUSAO_ARQUIVO_COD_BARRAS === key) {
    loginControlePCP = 'composição.animale'
  }

  if(TipoDivergenciasKey.INCLUSAO_ARQUIVO_RISCO === key) {
    loginControlePCP = 'cad.atelier'
  }

  return [
    <ColaboradorResponsavelComponent
      loading={loadingResponsavel}
      nome={loginControlePCP ?? data?.findResponsavelArea?.login}
    />,
    <AreaResponsavelComponent area={area.text} />,
    <LabelValueComponent label='Tipo da divergência' value={tipoDivergencia} />,
    <LabelValueComponent
      label='Data de criação'
      value={moment().format('LL')}
    />,
  ];
}

const useStyles = makeStyles(() =>
  createStyles({
    circle: {
      alignItems: 'center',
      border: '2px solid #F4F3F3',
      borderRadius: '48px',
      display: 'flex',
      height: '32px',
      justifyContent: 'center',
      width: '32px',
    },
  })
);

const FinalStepTemplate: FunctionComponent<{
  infoComponents: Array<JSX.Element>;
  infoMessage?: JSX.Element;
  loading?: boolean;
  onBack: (step?: Step) => void;
  onSetStep: (step: Step) => void;
  progressValue: number;
  stepNumber: string;
}> = (props) => {
  const { quantidadeTamanhos } = useContext<TamanhoGradeContextType>(TamanhoGradeContext)
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<Array<CustomFile>>([]);

  const tipoDivergenciaFaltaAviamento = props.infoComponents[2]?.props.value === 'Falta de aviamento'
  let hasTagPreco = false;
  let materialTag: any = '';
  let totalTagPreco = 0;
  let finalMessageTag = 'Quantidade de códigos de barras:\n\n';

  const verificaQuantidade = () => {
    let qtdTotalArray = [];
    for (const item of Object.values(quantidadeTamanhos)) {
      qtdTotalArray.push(Object.values(item)[0])
    }
    const qtdTotalContext = qtdTotalArray.reduce((accumulator, value) => {
      return accumulator + value;
    }, 0);
    return qtdTotalContext === totalTagPreco;
  };

  if (tipoDivergenciaFaltaAviamento) {
    const materiaisFaltaDeAviamento = props.infoComponents[4]?.props.materiais;
    const tagsArray: any = TagPreco(props);

    materiaisFaltaDeAviamento?.forEach((material: any) => {
      Object.values(tagsArray).forEach((item: any) => {
        if (Object.keys(item).toString() === material.value) {
          totalTagPreco = Number(material.InfoChildren?.[0].value);
          hasTagPreco = true;
          materialTag = Object.values(item).toString().replace(/^\s+|\s+$/g, "");;
        }
      })
    });

    if (hasTagPreco){
      let tagMessage = '';
      for (const [key, value] of Object.entries(quantidadeTamanhos)) {
        tagMessage += key + ' - ' + Object.values(value) + '\n'
      }
      finalMessageTag += tagMessage
    }
  }

  useEffect(() => {
    if (tipoDivergenciaFaltaAviamento && hasTagPreco) {
      setMessage(finalMessageTag);
    }
  }, [finalMessageTag, tipoDivergenciaFaltaAviamento, hasTagPreco])

  const onSaveMessage = (message: string, files: Array<CustomFile>) => {
    if (tipoDivergenciaFaltaAviamento && hasTagPreco) {
      setMessage(finalMessageTag + '\n' + message);
      setFiles(files);
    } else {
      setMessage(message);
      setFiles(files);
    }
  };

  const buildAddPayload = () => {
    return {
      message,
      files,
    };
  };
  
  return (
    <>
    <React.Fragment>
      <NovaDivergenciaProgressBar
        value={props.progressValue}
      ></NovaDivergenciaProgressBar>
      <DialogContent
        className='backgroundNeutral50 minHeightDivergenciaModalContent divergenciaMaxWidth d-flex flexColumn'
        style={{ padding: '0 32px' }}
      >
        <FinalStepHeader stepNumber={props.stepNumber}></FinalStepHeader>
        <Box padding='0 16px' className='flexGrow1 d-flex flexColumn'>
          <Grid container spacing={3}>
            {props.infoComponents.map((item, index) => (
              <Grid item xs={6} key={`info-${index}`}>
                {item}
              </Grid>
            ))}
          </Grid>

          {tipoDivergenciaFaltaAviamento && hasTagPreco && (
            <div className='mt16'>
              <h4 className='mx8'>Selecione a quantidade de ' <u>{materialTag}</u> ' por tamanho:</h4>
              <TamanhoGrade></TamanhoGrade>
            </div>
          )}

          <Grid container>
            <Grid item xs={12} className='mt32 mb16 d-flex'>
              <UserAvatar></UserAvatar>
              <ChatInput onSaveMessage={onSaveMessage}></ChatInput>
            </Grid>
          </Grid>
          {props.infoMessage && (
            <Grid container className='mtAuto'>
              <Grid item xs={12}>
                <div
                  className='d-flex align-item-center mb32'
                  style={{
                    padding: '16px 24px',
                    border: '2px solid #F4F3F3',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                  }}
                >
                  <span className={classes.circle}>
                    <InfoIcon className='iconNeutral500'></InfoIcon>
                  </span>
                  <span className='body2 medium neutral500'>
                    {props.infoMessage}
                  </span>
                </div>
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <Divider></Divider>
      <DialogActions
        className='divergenciaMaxWidth'
        style={{ padding: '30px 32px' }}
      >
        <FinalStepFooter
          tipoDivergenciaFaltaAviamento={tipoDivergenciaFaltaAviamento}
          hasTagPreco={hasTagPreco}
          verificaQuantidade={verificaQuantidade()}
          loading={props.loading || false}
          onBack={props.onBack}
          onSetStep={props.onSetStep}
          addPayload={buildAddPayload()}
        ></FinalStepFooter>
      </DialogActions>
    </React.Fragment>
    </>
  );
};

export default FinalStepTemplate;

export function useBuildFinalStepTemplate({
  addInfoComponents = [],
  area,
  infoMessage,
  loading = false,
  onBack,
  onSetStep,
  stepNumber = '2',
  tipoDivergencia,
  key
}: {
  addInfoComponents?: Array<JSX.Element>;
  area: Area;
  infoMessage?: JSX.Element;
  loading?: boolean;
  onBack: (step?: Step) => void;
  onSetStep: (step: Step) => void;
  stepNumber?: string;
  tipoDivergencia: string;
  key?: TipoDivergenciasKey
}) {
  const progressValue = 90;
  const infoComponents = useBuildDefaultInfoComponent(area, tipoDivergencia, key);
  return (
    <FinalStepTemplate
      infoComponents={[...infoComponents, ...addInfoComponents]}
      infoMessage={infoMessage}
      loading={loading}
      onBack={onBack}
      onSetStep={onSetStep}
      progressValue={progressValue}
      stepNumber={stepNumber}
    />
  );
}
