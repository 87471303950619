import { useMutation } from '@apollo/client';

import React, { FunctionComponent, useContext } from 'react';
import { DivergenceContext, DivergenceContextType } from "../../../../../context";
import { updateArquivo } from '../../mutations'
import { useLoggedUser } from '../../../../../../app/support/UseLoggedUser';
import { createStyles, Theme, CircularProgress, Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Backdrop, makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 99999,
            color: "#fff",
        },
    })
);

const DeleteFileComponent: FunctionComponent = () => {

    const { showDeleteFile, setShowDeleteFile, deleteFileSelected, setDeleteFileSelected, setFeedbackDeleteFile } = useContext<DivergenceContextType>(DivergenceContext);

    const { data } = useLoggedUser();

    const classes = useStyles();

    const loginUsuario = data?.getLoggedUser.login;


    const handleCloseDeleteFile = () => {
        setDeleteFileSelected({})
        setShowDeleteFile(false);
    }

    const [handleProcessar, { loading: loadingUpdate }] = useMutation(
        updateArquivo,
        {
            variables: {
                id_arquivo: deleteFileSelected.id_arquivo,
                usuario_inativacao: loginUsuario
            },
            onCompleted(data) {
                handleCloseDeleteFile()
                setFeedbackDeleteFile({
                    message: 'Arquivo deletado com sucesso!',
                    show: true,
                    type: "success",
                });
                window.location.reload();
            },
            onError(error) {
                setFeedbackDeleteFile({
                    message: 'Erro ao deletar o arquivo!',
                    show: true,
                    type: "error",
                });
            },
        }
    );

    return (
        <>
            {
                loadingUpdate ?

                    <Backdrop className={classes.backdrop} open={loadingUpdate}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    :

                    <Dialog
                        fullWidth
                        open={showDeleteFile}
                        onClose={() => setShowDeleteFile(false)}
                    >
                        <DialogTitle>Deletar Arquivo</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText>
                                <span>Tem certeza que deseja deletar o arquivo <strong>{deleteFileSelected?.nome_original}</strong>?</span>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="text"
                                className="primary500"
                                onClick={() => handleCloseDeleteFile()}
                            >
                                <span className="text-transform-initial bold">
                                    Voltar
                                </span>
                            </Button>
                            <Button
                                variant="contained"
                                className="backgroundPrimary500"
                                onClick={() => handleProcessar()}
                            >
                                <span className="text-transform-initial neutral000 bold">
                                    Sim
                                </span>
                            </Button>
                        </DialogActions>
                    </Dialog>
            }
        </>
    );
};

export default DeleteFileComponent;
