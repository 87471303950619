import { gql } from '@apollo/client'

export const updateArquivo = gql`
  mutation updateMensagemArquivo(
    $id_arquivo: Int!
    $usuario_inativacao: String!
  ) {
    updateMensagemArquivo (
      id_arquivo: $id_arquivo
      usuario_inativacao: $usuario_inativacao
    ) {
      id_arquivo
      usuario_inativacao
    }
  } 
`