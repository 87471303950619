import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { FunctionComponent, useState, useContext } from 'react';
import { ReactComponent as ItemAdd } from '../../../../../assets/icons/itemAdd.svg';
import { ReactComponent as WarningIcon } from '../../../../../assets/icons/warning.svg';
import SelectTipoDivergenciaStep from './SelectTipoDivergenciaStep';
import { TipoDivergenciaComponent } from './TiposDeDivergencias';
import { TamanhoGradeContext, TamanhoGradeContextType } from '../../../../context/TamanhoGradeContext'
import { DivergenceContext } from '../../../../context';
import PopUp from '../../../../components/PopUp';
import { ProductContext, ProductContextType } from '../../../../context/Product';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import "./toastStyles.css"; 


export type Step = {
  component: TipoDivergenciaComponent;
  isFinalStep?: boolean;
  payload?: Object;
};

const firstStep = {
  component: SelectTipoDivergenciaStep,
};

const NovaDivergenciaButton: FunctionComponent<{
  classButton?: string;
  classText?: string;
  iconClass: string;
  onCloseModal: Function;
  variant?: 'text' | 'outlined' | 'contained';
}> = ({
  classButton = '',
  classText = '',
  onCloseModal: onCloseModalCallback,
  variant = 'outlined',
  iconClass,
}) => {
    const { setQuantidadeTamanhos } = useContext<TamanhoGradeContextType>(TamanhoGradeContext)
    const { product } = useContext<ProductContextType>(ProductContext);
    const [openModalState, setOpenModal] = useState(false);
    const [openConfirmExitDialog, setOpenConfirmDialog] = useState(false);
    const [selelectStep, setSelectedStep] = useState<Step>(firstStep);

    const ctx = useContext(DivergenceContext)

    const isOPCancelada = product?.Producao_PPP_OP[0].Ordem_Producao.op_cancelada

    const handleSetStep = (value: Step) => {
      setSelectedStep(value);
    };

    const handleBackButton = (value?: Step) => {
      setSelectedStep(value || firstStep);
      setQuantidadeTamanhos({});
    };

    const openModal = () => {
      if(isOPCancelada) {
       toast.error("Ordem de produção encerrada! Não é possível adicionar divergência.", {
        className: "custom-toast",
        progressClassName: "custom-progress",
      });
        return
      }
      setOpenModal(true)
    };
    const closeModal = () => {
      setOpenModal(false);
      setSelectedStep(firstStep);
      closeConfirmDialog();
      onCloseModalCallback();
      setQuantidadeTamanhos({});
      closeSobras();
    };

    const openConfirmDialog = () => {
      if (selelectStep.isFinalStep) {
        return closeModal();
      }

      setOpenConfirmDialog(true);
    };
    const closeConfirmDialog = () => {
      setOpenConfirmDialog(false);
    };

    const closeCalledContinue = () => {
      ctx.handleOpenClosedFn()
    }

    const closeStopFlow = () => {
      closeModal()
      ctx.handleOpenClosedFn()
    }

    const closeSobras = () => {
      ctx.handleOpenClosedFnFiles()
      ctx.handleOpenClosedFnSobras()
    }

    const titleClass = clsx('ml8 body2 bold text-transform-initial', classText);

    const SelectedStep = selelectStep.component;
    return (
      <div>
        <PopUp
          open={!!ctx.popUpDivergenciaDuplicada?.open}
          onClose={closeStopFlow}
          onConfirm={closeCalledContinue}
          title={ctx.popUpDivergenciaDuplicada?.title || ""}
          content={ctx.popUpDivergenciaDuplicada?.content || ""} 
          loading={true} 
        />
        <Button className={classButton} variant={variant} onClick={openModal}>
          <ItemAdd className={iconClass}></ItemAdd>
          <span className={titleClass}>Registrar divergência</span>
        </Button>
        <Dialog
          aria-describedby='alert-dialog-description'
          aria-labelledby='alert-dialog-title'
          onClose={closeConfirmDialog}
          open={openConfirmExitDialog}
        >
          <DialogTitle style={{ padding: '16px 32px 0' }}>
            <div className='h6 headline medium primary500'>Cancelar registro</div>
          </DialogTitle>
          <DialogContent style={{ padding: '16px 32px 24px 32px' }}>
            <div className='body1 medium neutral500'>
              <div>Deseja realmente cancelar o</div>
              <div>registro de uma nova</div>
              <div>divergência?</div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal}>
              <span className='bold neutral300'>CANCELAR</span>
            </Button>
            <Button onClick={closeConfirmDialog}>
              <span className='bold primary500'>VOLTAR</span>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          disableBackdropClick={true}
          id='novaDivergenciaElement'
          maxWidth='md'
          open={openModalState}
          scroll='paper'
        >
          <DialogTitle
            className='divergenciaMaxWidth'
            style={{ padding: '24px 32px' }}
          >
            <div className='d-flex align-item-center'>
              <WarningIcon className='iconPrimary500'></WarningIcon>

              <span className='ml16 body1 bold neutral700'>
                Registrar divergência
              </span>
              <IconButton
                className='mlAuto'
                aria-label='close'
                onClick={openConfirmDialog}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <SelectedStep
            onBack={handleBackButton}
            onClose={closeModal}
            onSetStep={handleSetStep}
            payload={selelectStep.payload}
          ></SelectedStep>
        </Dialog>
      </div>
    );
  };

export default NovaDivergenciaButton;
